import axios from 'axios'
import Cookies from 'js-cookie'
import store from '@/store'
import { checkTokenValidity } from '@/helper/check-token-validity'
let api = axios.create({
  baseURL: '/api',
  timeout: 59000,
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': Cookies.get('csrftoken'),
  },
})

api.interceptors.request.use(
  async (response) => {
    if (!response.url.includes('uploads')) {
      return response
    }
    const validation = checkTokenValidity()
    if (!validation.accessible && validation.refreshable) {
      const { access } = await store.dispatch('account/refreshToken')
      // overwrite auth of current request
      // vuex handles overwrite of default header for future requests
      response.headers['Authorization'] = `JWT ${access}`
      return response
    }
    return response
  },
  (err) => {
    return Promise.reject(err)
  }
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { response, message } = error

    const props = {
      error: message,
      ...(response && {
        status: response.status,
        url: response.config.url,
      }),
    }

    window.plausible?.('Request error', { props })
    if (response && response.status === 401) {
      store.dispatch('account/forceLogout', 'Access denied')
    }
    if (
      response &&
      response.status === 403 &&
      response.data.detail === 'Access denied' &&
      store.state.account.user
    ) {
      store.dispatch('account/forceLogout', error.response.data.detail)
    }

    return Promise.reject(error)
  }
)

export default api
